import { useStaticQuery, graphql } from "gatsby"
import React from "react"
import styled from "styled-components"
import { BaseButton } from "./styledComponents"
import { Container } from "./styledComponents"
import GoTopArrow from "../components/goTopArrow"
import { MediumMobileFont, SmallMobileFont } from "./typography"
import FlowMapImage from "./flowMapImage"

const Header = styled.h2`
  text-align: center;
  margin-bottom: 0.75rem;
`
const Content = styled.p`
  font-size: 1.5rem;
  text-align: center;
  margin: 0 74px 1rem;
  @media screen and (max-width: 768px) {
    margin: 0 0 1rem;
  }
`

const ContentWrapper = styled.div`
  display: flex;
  position: relative;
  flex-direction: column;
  align-items: center;
  max-width: 620px;
  margin: 150px auto 250px;
  padding: 46px;
  border: 1px solid ${(props) => props.theme.secondary};
  @media screen and (max-width: 768px) {
    padding: 26px 12px;
    margin: 90px auto 120px;
  }
`

const WingImageContainer = styled.div`
  position: absolute;
  z-index: -1;
  bottom: -50%;
  width: 26.5vw;
  left: ${(props) => (props.side === "left" ? "auto" : "60%")};
  right: ${(props) => (props.side === "left" ? "60%" : "auto")};
  transform: rotateY(${(props) => (props.side === "left" ? "0" : "180deg")});
  @media screen and (max-width: 900px) {
    width: 40vw;
  }
  @media screen and (max-width: 500px) {
    bottom: -25%;
  }
`
const Button = styled.a`
  display: inline-block;
  position: relative;
  text-align: center;
  line-height: 1.25;
  font-family: "Montserrat";
  font-size: ${(props) =>
    ["small", "medium"].includes(props.size) ? "1rem" : "1.125rem"};
  padding: ${(props) =>
    props.size === "small"
      ? "0.625rem 3rem"
      : props.size === "medium"
      ? "0.75rem 2.5rem"
      : "0.9375rem 3rem"};
  /* small medium big */
  @media (max-width: 1195px) {
    font-size: 1rem;
    padding: 0.625rem 2rem;
  }
  border: none;
  color: #fff;
  font-weight: bold;
  background: ${(props) => props.theme.secondary};
  box-shadow: 0px 3px 6px #00000029;
  cursor: pointer;
  border: 1px solid ${(props) => props.theme.secondary};
  transition: color 0.1s ease-in-out, background 0.2s ease-in-out;

  :focus {
    border-style: dashed;
    background: #fff;
    color: ${(props) => props.theme.secondary};
    outline: none;
  }

  :hover {
    color: ${(props) => props.theme.secondary};
    background: #fff;
  }
`

const LastCTASection = ({
  title,
  text,
  buttonText,
  buttonUrl,
  scrollToTop,
}) => {
  const data = useStaticQuery(
    graphql`
      query {
        file(name: { eq: "lastCtaWing" }) {
          publicURL
        }
      }
    `
  )
  return (
    <Container>
      <ContentWrapper>
        <WingImageContainer side="left">
          <FlowMapImage
            imagePublicURL={data.file.publicURL}
            size={{ width: 384, height: 378 }}
          />
        </WingImageContainer>
        <Header>
          <MediumMobileFont>{title}</MediumMobileFont>
        </Header>
        <div style={{ maxWidth: "90%" }}>
          <Content>
            <SmallMobileFont>{text}</SmallMobileFont>
          </Content>
        </div>
        {scrollToTop ? (
          <Button
            onClick={() => window.scrollTo({ top: 0, behavior: "smooth" })}
          >
            {buttonText}
          </Button>
        ) : (
          <BaseButton to={buttonUrl}>{buttonText}</BaseButton>
        )}
        <WingImageContainer>
          <FlowMapImage
            flipMouse="x"
            imagePublicURL={data.file.publicURL}
            size={{ width: 384, height: 378 }}
          />
        </WingImageContainer>
      </ContentWrapper>
      {scrollToTop ? "" : <GoTopArrow />}
    </Container>
  )
}

export default LastCTASection

import React from "react"
import styled from "styled-components"

const ArrowButton = styled.button`
  position: absolute;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  background: none;
  border: 1px solid ${(props) => props.theme.secondary};
  width: 70px;
  height: 70px;
  bottom: -130px;
  right: 0;
  color: #fff; /* Fallback for older browsers */
  color: rgba(255, 255, 255, 0);
  transition: color 0.1s ease-in-out, background 0.2s ease-in-out;
  :focus,
  :hover {
    outline: none;
    background: ${(props) => props.theme.secondary};
    div {
      animation: 2s ease-in-out forwards infinite jump;
      ::before {
        border-color: #fff;
      }
    }
  }
  @keyframes jump {
    0% {
      transform: translateY(5px);
    }
    30% {
      transform: translateY(-5px);
    }
    100% {
      transform: translateY(5px);
    }
  }
`

const ArrowIcon = styled.div`
  position: relative;
  ::before {
    content: "";
    top: 50%;
    left: 50%;
    width: 24px;
    height: 24px;
    border-bottom: 5px ${(props) => props.theme.secondary} solid;
    border-left: 5px ${(props) => props.theme.secondary} solid;
    transform: translate(-50%, -25%) rotate(135deg);
    position: absolute;
  }
`

const GoTopArrow = () => {
  return (
    <ArrowButton
      aria-label="Go to top"
      type="button"
      onClick={() => window.scrollTo({ top: 0, behavior: "smooth" })}
    >
      <ArrowIcon>></ArrowIcon>
    </ArrowButton>
  )
}
export default GoTopArrow

import React, { useState } from "react"
import { useStaticQuery, graphql } from "gatsby"
import { Container } from "./styledComponents"
import ArticleElement from "./articleElement"
import styled from "styled-components"

const Categories = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  @media (max-width: 1195px) {
    justify-content: center;
    flex-wrap: wrap;
  }
  .category {
    border: none;
    background: none;
    cursor: pointer;
    margin: 2rem;
    font: 700 1.5rem/1.8125rem "Montserrat";
    color: ${(props) => props.theme.primary};
    @media (max-width: 1195px) {
      margin: 0.5rem 2rem;
    }
    @media (max-width: 768px) {
      margin: 0.5rem;
    }
    :focus {
      outline: none;
    }
  }
  .category.active {
    text-decoration: line-through ${(props) => props.theme.secondary};
    text-decoration-thickness: 1px;
  }
`
const ArticlesGrid = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  margin: 68px 0;
  @media (max-width: 1195px) {
    grid-template-columns: 1fr 1fr;
  }
  @media (max-width: 840px) {
    grid-template-columns: 1fr;
  }
`

const LoadMoreButton = styled.button`
  display: inline-block;
  position: relative;
  white-space: nowrap;
  font-family: "Montserrat";
  font-size: ${(props) =>
    ["small", "medium"].includes(props.size) ? "1rem" : "1.125rem"};
  padding: ${(props) =>
    props.size === "small"
      ? "0.625rem 3rem"
      : props.size === "medium"
      ? "0.75rem 2.5rem"
      : "0.9375rem 3rem"};
  /* small medium big */
  border: none;
  color: #fff;
  font-weight: bold;
  background: ${(props) => props.theme.secondary};
  box-shadow: 0px 3px 6px #00000029;
  cursor: pointer;
  border: 1px solid ${(props) => props.theme.secondary};
  transition: color 0.1s ease-in-out, background 0.2s ease-in-out;
  :focus {
    border-style: dashed;
    background: #fff;
    color: ${(props) => props.theme.secondary};
    outline: none;
  }
  :hover {
    color: ${(props) => props.theme.secondary};
    background: #fff;
  }
`

const ArticlesWithCategoriesGrid = ({ articles }) => {
  const { wordpressPage } = useStaticQuery(graphql`
    query {
      wordpressPage(
        template: { eq: "page-templates/page-global-settings.php" }
      ) {
        cmb2 {
          global_settings {
            loadMoreArticles
          }
        }
      }
    }
  `)
  const [activeCategory, setActiveCategory] = useState(
    articles[0].categories[0].wordpress_id
  )
  const [numberOfArticles, setNumberOfArticles] = useState(6)
  const handleCategoryChange = (category) => () => {
    setNumberOfArticles(6)
    setActiveCategory(category)
  }
  const articlesTaxonomy = []
  const articlesObject = {}
  for (const article of articles) {
    for (const taxonomy of article.categories) {
      const projectToAdd = {
        title: article.title,
        featured_media: article.featured_media,
      }
      if (Array.isArray(articlesObject[taxonomy.wordpress_id])) {
        articlesObject[taxonomy.wordpress_id].push(projectToAdd)
      } else {
        articlesObject[taxonomy.wordpress_id] = [projectToAdd]
      }
      let addNewTaxonomy = true
      for (const articleTaxonomy of articlesTaxonomy) {
        if (articleTaxonomy.wordpress_id === taxonomy.wordpress_id) {
          addNewTaxonomy = false
        }
      }
      if (addNewTaxonomy) {
        articlesTaxonomy.push({
          wordpress_id: taxonomy.wordpress_id,
          name: taxonomy.name,
        })
      }
    }
  }

  const filteredArticles = articles.filter((article) => {
    for (const taxonomy of article.categories) {
      if (taxonomy.wordpress_id === activeCategory) return true
    }
    return false
  })
  const stripedFilteredArticles = filteredArticles.slice(0, numberOfArticles)

  return (
    <Container>
      <Categories>
        {articlesTaxonomy.map((taxonomy) => (
          <button
            aria-label={`Show ${taxonomy.name} category`}
            type="button"
            key={taxonomy.wordpress_id}
            onClick={handleCategoryChange(taxonomy.wordpress_id)}
            className={
              taxonomy.wordpress_id === activeCategory
                ? "active category"
                : "category"
            }
          >
            {taxonomy.name} ({articlesObject[taxonomy.wordpress_id].length})
          </button>
        ))}
      </Categories>
      <ArticlesGrid>
        {stripedFilteredArticles.map(
          ({ title, excerpt, featured_media, path }) => (
            <div key={title}>
              <ArticleElement
                style={{ margin: "10px auto" }}
                title={title}
                excerpt={excerpt}
                localFile={featured_media.localFile}
                path={path}
              />
            </div>
          )
        )}
      </ArticlesGrid>
      <div style={{ display: "flex", justifyContent: "center" }}>
        {filteredArticles.length >= numberOfArticles ? (
          <LoadMoreButton
            type="button"
            onClick={() => setNumberOfArticles(numberOfArticles + 6)}
          >
            {wordpressPage.cmb2.global_settings.loadMoreArticles}
          </LoadMoreButton>
        ) : null}
      </div>
    </Container>
  )
}

export default ArticlesWithCategoriesGrid

import React from "react"
import { Link, useStaticQuery, graphql } from "gatsby"
import styled, { css } from "styled-components"
import { BaseButton } from "./styledComponents"
import BaseImg from "./baseImg"
import { useAnimatedBorder } from "../helpers"
import decodeHtmlCharCodes from "../utils/decodeHtmlCharCodes"
import * as PropTypes from "prop-types"

const ArticleHeader = styled.h1`
  font-size: 1.125rem;
  line-height: 1.375rem;
  margin-top: 3.25rem;
  margin-bottom: 0.75rem;
`

const ArticleContent = styled.div`
  margin-bottom: 3.5rem;

  & > p {
    margin: 0;
  }
`

const LinkWrapper = styled(Link)`
  :hover {
    color: ${(props) => props.theme.primary};
  }
`

const ArticleBox = styled.article`
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  max-width: 330px;
  padding: 2.25rem 0;
`

const Border = styled.div`
  position: absolute;
  z-index: -1;
  top: 0;
  height: 100%;
  left: 2.25rem;
  width: calc(100% - 4.5rem);
  height: calc(215px + 4.5rem);
  border-top: 1px solid ${(props) => props.theme.secondary};

  ${(props) =>
          props.side === "bottom" &&
          css`
            top: auto;
            bottom: 0;
            height: 7rem;
            border-top: none;
            border-bottom: 1px solid ${(props) => props.theme.secondary};
          `}
  ::before,
  ::after {
    content: "";
    top: 0;
    position: absolute;
    ${(props) =>
            props.side === "bottom" &&
            css`
              top: auto;
              bottom: 0;
              border-left: none;
            `}
    height: 100%;
    transition: transform 1s ease-out;
  }

  ::before {
    left: 0;
    border-left: 1px solid ${(props) => props.theme.secondary};
    transform: scaleY(calc(1 + var(--scale)));
    transform-origin: top;
    ${(props) =>
            props.side === "bottom" &&
            css`
              transform: scaleY(calc(1 - var(--scale)));
              transform-origin: bottom;
            `}
  }

  ::after {
    right: 0;
    border-right: 1px solid ${(props) => props.theme.secondary};
    transform: scaleY(calc(1 - var(--scale)));
    transform-origin: top;
    ${(props) =>
            props.side === "bottom" &&
            css`
              transform: scaleY(calc(1 + var(--scale)));
              transform-origin: bottom;
            `}
  }
`

const ReadMore = styled(BaseButton)`
  @media screen and (max-width: 768px) {
    text-align: center;
  }
`

const ArticleInner = ({ html, localFile, str, to, wordpressPage }) => <>
  <LinkWrapper to={to}>
    <div>
      <BaseImg style={{ width: "100%" }} localFile={localFile} />
      <ArticleHeader>{decodeHtmlCharCodes(str)}</ArticleHeader>
      <ArticleContent dangerouslySetInnerHTML={{ __html: html }} />
    </div>
  </LinkWrapper>
  <div>
    <ReadMore to={to} size="small">
      {wordpressPage.cmb2.global_settings.readMore}
    </ReadMore>
  </div>
</>

ArticleInner.propTypes = {
  to: PropTypes.any,
  localFile: PropTypes.any,
  str: PropTypes.any,
  html: PropTypes.any,
  wordpressPage: PropTypes.any,
}
const ArticleElement = ({ style, title, excerpt, localFile, path }) => {
  const { wordpressPage } = useStaticQuery(graphql`
      query {
          wordpressPage(
              template: { eq: "page-templates/page-global-settings.php" }
          ) {
              cmb2 {
                  global_settings {
                      readMore
                  }
              }
          }
      }
  `)
  const [boxRef] = useAnimatedBorder(0.2)

  return (
    <ArticleBox style={style} ref={boxRef}>
      <Border side="top"  />
      <ArticleInner to={path} localFile={localFile} str={title} html={excerpt} wordpressPage={wordpressPage} />
      <Border side="bottom"  />
    </ArticleBox>
  )
}

export default ArticleElement
